import { FrameHelper } from "@/helpers/frame";
import { TimelineAction, TimelineRow } from "@xzdarcy/react-timeline-editor";
import { isWithinCut } from "../helpers/caption";
import { convertCropsToRelative } from "../helpers/crop";
import {
  converCutsToRelative,
  getMagnetedCuts,
  getTotalDurationOfCuts,
  splitCut,
} from "../helpers/cuts";
import { TimelineEffectId } from "../hooks/useTimeline";
import { Crop, Cut, WordTimecode } from "../types/player";

export const convertCutsToTimelineRow = (
  cuts: Cut[],
  wordsTimecodes: WordTimecode[],
  id: string,
  effectId: string,
  duration: number
): CutTimelineRow => {
  return {
    id,
    actions: [
      ...cuts?.map((cut, i) => {
        return {
          id: i.toString(),
          start: cut.start,
          end: cut.end,
          effectId,
          selected: false,
          movable: false,
          data: {
            words: wordsTimecodes?.filter((word) =>
              isWithinCut(word.start, word.end, cut)
            ),
          },
        };
      }),
      {
        id: "end-ruler",
        start: duration,
        end: duration,
        effectId: TimelineEffectId.Rulers,
        selected: false,
        movable: false,
        flexible: false,
      },
    ],
  };
};

export interface CutTimelineAction extends TimelineAction {
  data: {
    words: WordTimecode[];
  };
}
export interface CropTimelineAction extends TimelineAction {
  data: {
    cropId: string;
    absolute: {
      start: number;
      end: number;
      startFrame: number;
      endFrame: number;
    };
    relative: {
      start: number;
      end: number;
      startFrame: number;
      endFrame: number;
    };
  };
}

export interface CropTimelineRow extends TimelineRow {
  actions: (CropTimelineAction | TimelineAction)[];
}
export interface CutTimelineRow extends TimelineRow {
  actions: (CutTimelineAction | TimelineAction)[];
}

export const convertCropsToTimelineRow = (
  id: string,
  cuts: Cut[],
  crops: Crop[],
  fps: number,
  effectId: string
): CropTimelineRow => {
  let row: CropTimelineRow = {
    id,
    actions: [],
  };
  const totalDuration = getTotalDurationOfCuts(cuts);

  // filter crops that are within the cuts, if they overlap the cuts, then we need to adjust the start and end frame
  const relativeCrops = convertCropsToRelative(crops, cuts, fps);
  // console.log("relativeCrops", relativeCrops);

  // Add the cuts as actions (cuts separators)
  if (cuts.length > 0)
    getMagnetedCuts(converCutsToRelative(cuts)).forEach((cut) => {
      row.actions.push({
        id: cut.end.toString(),
        start: cut.end,
        end: cut.end,
        effectId: "effect2",
        selected: false,
        movable: false,
        flexible: false,
      });
    });
  // debugger;
  row.actions.push(
    ...relativeCrops.map((crop, i) => {
      // const isInTimeframe = start < totalDuration && end > 0;
      const absStart = FrameHelper.convertFrameToAbsolute(
        crop.startFrame,
        cuts,
        fps
      );
      const absEnd = FrameHelper.convertFrameToAbsolute(
        crop.endFrame,
        cuts,
        fps
      );
      return {
        id: crop.index.toString(),
        start: crop.startFrame / fps,
        end: crop.endFrame / fps,
        effectId,
        selected: false,
        movable: false,
        maxEnd: totalDuration,
        minStart: 0,
        data: {
          absolute: {
            start: absStart / fps,
            end: absEnd / fps,
            startFrame: absStart,
            endFrame: absEnd,
          },
          relative: {
            start: crop.startFrame / fps,
            end: crop.endFrame / fps,
            startFrame: crop.startFrame,
            endFrame: crop.endFrame,
          },
        },
      };
    })
  );

  // debugger;
  return row;
};
export const fillEmptyActions = (
  rows: TimelineRow[],
  end: number,
  fps: number
) => {
  const newRows: TimelineRow[] = [];
  const splittedActionsRows: TimelineRow[] = [];
  const oneFrameTime = 1 / fps;
  let allActions = rows.flatMap((row) => row.actions);

  // Fill all the empty space between actions
  for (const row of rows) {
    const actions = row.actions;
    // Ensure actions are sorted by start time
    actions.sort((a, b) => a.start - b.start);

    let filledActions: TimelineAction[] = [];
    let lastEnd = 0;

    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];

      const isRulers = action.effectId === TimelineEffectId.Rulers;

      if (isRulers) {
        filledActions.push(action);
        continue;
      }

      if (action.start > lastEnd) {
        filledActions.push({
          id: `empty-${crypto.randomUUID()}`,
          start: lastEnd + oneFrameTime,
          end: action.start - oneFrameTime,
          effectId: TimelineEffectId.Empty,
          selected: false,
          movable: false,
          flexible: false,
        });
      }

      filledActions.push(action);
      lastEnd = action.end;
    }

    // Check if there is a gap between the last action's end and the specified end time
    if (lastEnd < end) {
      filledActions.push({
        id: `empty-${crypto.randomUUID()}`,
        start: lastEnd + oneFrameTime,
        end: end - oneFrameTime,
        effectId: TimelineEffectId.Empty,
        selected: false,
        movable: false,
        flexible: false,
      });
    }
    newRows.push({
      ...row,
      actions: filledActions,
    });
  }

  allActions = newRows.flatMap((row) => row.actions);

  // Split empty actions to match overlapped actions
  for (const row of newRows) {
    let splittedActions: TimelineAction[] = [];

    for (const action of row.actions) {
      if (action.effectId !== TimelineEffectId.Empty) {
        splittedActions.push(action);
        continue;
      }

      const splitPoints = allActions.map((action) => action.start);
      const splittedCuts = splitCut(
        { start: action.start, end: action.end },
        splitPoints
      ).map((cut, i, self) => {
        if (i == self.length - 1) return cut;
        return {
          start: cut.start,
          end: cut.end - oneFrameTime, // Subtract 1 frame to avoid overlapping frames
        };
      });

      for (const splittedCut of splittedCuts) {
        splittedActions.push({
          id: `empty-${crypto.randomUUID()}`,
          start: splittedCut.start,
          end: splittedCut.end,
          effectId: TimelineEffectId.Empty,
          selected: false,
          movable: false,
          flexible: false,
        });
      }
    }
    splittedActionsRows.push({
      ...row,
      actions: splittedActions,
    });
  }

  return splittedActionsRows;
};

export const convertCropsToTimelineRows = (
  crops: Crop[],
  cuts: Cut[],
  id: string,
  effectId: string,
  fps: number
): TimelineRow[] => {
  const rows: TimelineRow[] = [];
  // filter crops that are within the cuts, if they overlap the cuts, then we need to adjust the start and end frame
  const relativeCrops = convertCropsToRelative(crops, cuts, fps);
  const relativeCuts = getMagnetedCuts(converCutsToRelative(cuts));
  const totalDuration = getTotalDurationOfCuts(cuts);

  relativeCrops.forEach((crop, i) => {
    // const isInTimeframe = start < totalDuration && end > 0;
    const absStart = FrameHelper.convertFrameToAbsolute(
      crop.startFrame,
      cuts,
      fps
    );
    const absEnd = FrameHelper.convertFrameToAbsolute(crop.endFrame, cuts, fps);
    const action = {
      id: crop.index.toString(),
      start: crop.startFrame / fps,
      end: crop.endFrame / fps,
      effectId,
      selected: false,
      movable: false,
      maxEnd: totalDuration,
      minStart: 0,
      data: {
        cropId: crop.id,
        absolute: {
          start: absStart / fps,
          end: absEnd / fps,
          startFrame: absStart,
          endFrame: absEnd,
        },
        relative: {
          start: crop.startFrame / fps,
          end: crop.endFrame / fps,
          startFrame: crop.startFrame,
          endFrame: crop.endFrame,
        },
      },
    };

    let added = false;
    for (let row of rows) {
      if (!isOverlapping(action, row.actions)) {
        row.actions.push(action);
        added = true;
        break;
      }
    }
    const rulerActions = relativeCuts.map((cut) => {
      // subtract 0.5 frame to be in the middle of the crop actions (because 1 frame exlusive)
      const middleTime = cut.end - 0.5 / fps;
      return {
        id: cut.end.toString(),
        start: middleTime,
        end: middleTime,
        effectId: TimelineEffectId.Rulers,
        selected: false,
        movable: false,
        flexible: false,
      };
    });

    // If the crop action didn't fit in any existing row, create a new row for it.
    if (!added) {
      rows.push({
        id: `${id}-${i}`,
        actions: [...rulerActions, action],
      });
    }
  });

  return rows;
};

const isOverlapping = (
  action: TimelineAction,
  actions: TimelineAction[]
): boolean => {
  for (let existingAction of actions) {
    if (
      (action.start >= existingAction.start &&
        action.start < existingAction.end) ||
      (action.end > existingAction.start && action.end <= existingAction.end)
    ) {
      return true;
    }
  }
  return false;
};

export function isNumber(value: any): value is number {
  return typeof value === "number" && isFinite(value);
}
const punctuation = `!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`;

export function mergeContractions(wordsTimecodes: WordTimecode[]) {
  let mergedWords = [];

  for (let i = 0; i < wordsTimecodes.length; i++) {
    if (punctuation.includes(wordsTimecodes[i].text.trim().charAt(0))) {
      if (mergedWords.length > 0) {
        const previousWord: any = { ...mergedWords[mergedWords.length - 1] }; // Deep copy the previous word
        previousWord.text += wordsTimecodes[i].text.trim();
        previousWord.end = wordsTimecodes[i].end;
        mergedWords[mergedWords.length - 1] = previousWord;
      }
    } else {
      const word = { ...wordsTimecodes[i] }; // Deep copy the word
      mergedWords.push(word);
    }
  }

  return mergedWords;
}

// export function mergeQuotes(input: WordTimecode[]): WordTimecode[] {
//   const result: WordTimecode[] = [];

//   for (let i = 0; i < input.length; i++) {
//     let currentWord = input[i];

//     // Check if the current word starts with a quote
//     if (currentWord.text.trim().startsWith("«")) {
//       let nextWord = input[i + 1];
//       let nextNextWord = input[i + 2];

//       // Check if the next next word ends with a quote
//       if (nextNextWord && nextNextWord.text.trim().startsWith("»")) {
//         // Merge the three words
//         result.push({
//           start: currentWord.start,
//           end: nextNextWord.end,
//           segment_id: currentWord.segment_id,
//           text:
//             currentWord.text.trim() +
//             nextWord.text.trim() +
//             nextNextWord.text.trim(),
//         });

//         // Skip next two words
//         i += 2;
//         continue;
//       }
//     }

//     // If the word wasn't merged, add it to the result as it is
//     result.push(currentWord);
//   }

//   return result;
// }

export function mergeQuotes(input: WordTimecode[]): WordTimecode[] {
  let result: WordTimecode[] = [];

  for (let i = 0; i < input.length; i++) {
    let currentWord = input[i];
    let nextWord = input[i + 1];

    // Check if the current word starts with any type of quotation mark
    if (/^\p{Pi}/u.test(currentWord.text.trim())) {
      let nextWord = input[i + 1];

      // Merge the two words
      result.push({
        start: currentWord.start,
        end: nextWord.end,
        text: currentWord.text + nextWord.text,
      });

      // Skip next word
      i += 1;
      continue;

      // Check if the next word ends with any type of quotation mark
    } else if (nextWord && /^\p{Pf}/u.test(nextWord.text.trim())) {
      // Merge the two words
      result.push({
        start: currentWord.start,
        end: nextWord.end,
        text: currentWord.text + nextWord.text,
      });

      // Skip next word
      i += 1;
      continue;
    }

    // If the word wasn't merged, add it to the result as it is
    result.push(currentWord);
  }

  return result;
}

export function mergeBrokenNumbers(wordsTimecodes: WordTimecode[]) {
  let mergedNumbers = [];

  for (let i = 0; i < wordsTimecodes.length; i++) {
    try {
      let num = parseFloat(
        wordsTimecodes[i].text.replace(",", "").replace(" ", "")
      );

      if (isNumber(num)) {
        if (mergedNumbers.length > 0) {
          mergedNumbers[mergedNumbers.length - 1].text +=
            wordsTimecodes[i].text;
          mergedNumbers[mergedNumbers.length - 1].end = wordsTimecodes[i].end;
        } else {
          mergedNumbers.push(wordsTimecodes[i]);
        }
      } else {
        mergedNumbers.push(wordsTimecodes[i]);
      }
    } catch (error) {
      mergedNumbers.push(wordsTimecodes[i]);
    }
  }

  return mergedNumbers;
}

export const validateFileVideoDuration = async (
  file: File,
  maxDurationSeconds: number
) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      if (duration > maxDurationSeconds) {
        reject(new Error("Video too long"));
      } else {
        resolve(true);
      }
    };

    video.src = URL.createObjectURL(file);
  });
};

export const readVideoFileMetadata = async (file: File) => {
  return new Promise<HTMLVideoElement>((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      console.log(video);

      resolve(video);
    };

    video.src = URL.createObjectURL(file);
  });
};
