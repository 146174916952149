import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ComponentPropsWithoutRef, FC } from "react";
import { ButtonProps } from "./Button";

export type SelectOption<T> = {
  label: string;
  value: T;
};

type OptionsSelectProps = {
  onSelectOption: (value: string) => void;
  options: SelectOption<string>[];
  value?: string;
  loading?: boolean;
  loadingLabel?: string;
  variant?: ButtonProps["variant"];
} & ComponentPropsWithoutRef<"div">;

const OptionsSelect: FC<OptionsSelectProps> = ({
  onSelectOption,
  options,
  value,
  loading,
  loadingLabel,
  variant = "secondary",
  ...rest
}) => {
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!e.target) return;
    onSelectOption(e.target.value);
  };
  // label = label ?? loadingLabel;
  const _size: any = {
    s: "px-4 py-2",
    m: "px-6 py-3",

    l: "px-8 py-4",
  };
  const _variant: any = {
    primary:
      "bg-slate-950 border-slate-950 text-white fill-white hover:bg-slate-900 active:scale-95 duration-100",
    secondary:
      "border border-slate-200 text-slate-950 font-semibold text-slate-950 hover:bg-opacity-80 bg-white",
    danger: "bg-white border-red-500 text-red-500 hover:bg-opacity-80",
    tertiary: "text-slate-950 border-white/0 bg-transparant hover:bg-slate-200",
  };

  // console.log(options);
  return (
    <div className={`${rest.className}  flex gap-1 relative`}>
      <select
        value={value}
        onChange={handleSelect}
        // className="absolute inset-0 appearance-none"
        className={`${rest.className} ${_size["s"]} ${_variant[variant]} ${
          !rest.className?.includes("absolute") ? " relative" : ""
        } border will-change-transform overflow-hidden rounded-xl transition-all disabled:opacity-50 disabled:cursor-not-allowed shrink-0 appearance-none text-sm font-semibold pr-8 cursor-pointer outline-none w-full`}
      >
        {options.map((option, i) => (
          <option
            key={option.value}
            label={option.label}
            value={option.value}
          />
        ))}
      </select>
      <ChevronUpDownIcon className="w-4 h-4 absolute right-3 top-1/2 -translate-y-1/2 text-slate-500 z-50" />
    </div>
  );
};

export default OptionsSelect;
