import { ArrowPathIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import { ComponentPropsWithoutRef, FC, ReactElement } from "react";
import Button from "./Button";
import Typography from "./Typography";

type PropertySectionProps = {
  title: string;
  onReset?: () => void;
  trailing?: ReactElement;
  heading?: boolean;
} & ComponentPropsWithoutRef<"div">;

const PropertySection: FC<PropertySectionProps> = ({
  title,
  trailing,
  onReset,
  heading = false,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={`${rest.className} ${
        heading ? "flex flex-col gap-12 first:gap-0" : ""
      }`}
    >
      <div
        className={`flex justify-between gap-2 py-0.5 items-center  ${
          heading ? "sticky -top-4 z-50 bg-white dark:bg-slate-950" : ""
        }`}
      >
        <Typography
          className={`block ${
            heading
              ? "dark:text-white text-slate-700"
              : "dark:text-slate-500 text-slate-500"
          } mx-2`}
          variant="subtitle3"
        >
          {title}
        </Typography>
        {onReset && (
          <Button
            data-tooltip-id="global"
            data-tooltip-content={`Reset default ${title}`}
            size="s"
            className="ml-auto"
            onClick={onReset}
            variant="tertiary"
            leading={<ArrowPathIcon className="w-4 h-4" />}
          />
        )}
        {trailing}
        {!onReset &&
          !trailing && ( // Hack to keep the button box the same size
            <Button
              className="invisible opacity-0 pointer-events-none"
              leading={<FaceSmileIcon className="w-4 h-4" />}
              size="s"
              onClick={() => {}}
            />
          )}
      </div>
      {rest.children}
    </div>
  );
};

export default PropertySection;
