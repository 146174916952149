import {
  TimelineAction,
  TimelineEngine,
  TimelineRow,
  TimelineState,
} from "@xzdarcy/react-timeline-editor";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { OnScrollParams } from "react-virtualized";
import { useWindowSize } from "usehooks-ts";
import { EditorModes } from "../components/EditorModeTabs";
import { getTotalDurationOfCuts } from "../helpers/cuts";
import { Crop, Cut, WordTimecode } from "../types/player";
import {
  convertCropsToTimelineRows,
  convertCutsToTimelineRow,
  fillEmptyActions,
} from "../utils/video";

export const TIMELINE_CUTS_ROW_ID = `transcript-cuts`;
export const TIMELINE_CROP_ROW_ID = `crop-row`;
export const TIMELINE_EFFECT_IDS = {};

export enum TimelineEffectId {
  Cut = "effect0",
  Crop = "effect1",
  Rulers = "effect2",
  Empty = "effect3",
}

export const TIMELINE_PROPS = {
  defaultScale: 1,
  scaleSplitCount: 10,
  scaleWidth: 100,
  startLeft: 20,
  rowHeight: 40,
};

export const useTimeline = (
  timelineState: RefObject<TimelineState>,
  mode: EditorModes,
  cuts: Cut[],
  words: WordTimecode[],
  crops: Crop[],
  fps: number,
  videoDuration: number,
  clipDuration: number
) => {
  const [editorData, setEditorData] = useState<TimelineRow[]>([]);
  const [selectedActions, setSelectedActions] = useState<TimelineAction[]>([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [magnetMode, setMagnetMode] = useState(false);

  const [scale, setScale] = useState(TIMELINE_PROPS.defaultScale);
  const scaleRef = useRef(-1);

  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollLeftRef = useRef(scrollLeft);

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    // Called every second
    timelineState.current?.listener.on("beforeSetTime", handleBeforeSetTime);

    initScale();

    return () => {
      timelineState.current?.listener.off("beforeSetTime", handleBeforeSetTime);
    };
  }, [mode, windowWidth]);

  // useEffect(() => {
  //   initTimelineScrollLeft();
  // }, [mode]);

  useEffect(() => {
    // if (magnetMode) currentCuts = getMagnetedCuts(currentCuts);
    const cutsRows = [
      convertCutsToTimelineRow(
        cuts,
        words,
        TIMELINE_CUTS_ROW_ID,
        TimelineEffectId.Cut,
        videoDuration
      ),
    ];

    if (mode == "video") setEditorData(cutsRows);
  }, [cuts, magnetMode, mode]);

  useEffect(() => {
    if (!crops) return;
    const cropsRows = convertCropsToTimelineRows(
      crops,
      cuts,
      TIMELINE_CROP_ROW_ID,
      TimelineEffectId.Crop,
      fps
    ).reverse();
    const totalDuration = getTotalDurationOfCuts(cuts);
    const newEditorData = fillEmptyActions(cropsRows, totalDuration, fps);

    // console.log("crops", crops);
    if (mode == "crop") setEditorData(newEditorData);
  }, [crops, mode]);

  useEffect(() => {
    const selected = editorData.map((r) => r.actions.filter((a) => a.selected));
    setSelectedActions(selected.flat());
  }, [editorData]);

  useEffect(() => {
    setDeleteMode(selectedActions.length > 0);
  }, [selectedActions]);

  // useEffect(() => {
  //   initTimelineScale();
  //   const time = timelineState.current?.getTime();
  //   if (!time) return;

  //   timelineState.current?.setTime(time);

  //   time == 0 ? startCursorInView() : centerCursorInView(time, scale);
  // }, [windowWidth, mode]);

  // const initTimelineScrollLeft = () => {
  //   // Handler cursor position when switching between modes
  //   timelineState.current?.listener.on("afterSetTime", ({ time }) => {
  //     if (
  //       (cuts.length > 0 &&
  //         mode == "video" &&
  //         time > Math.round(cuts[0].start)) ||
  //       (mode == "crop" && time > 0)
  //     ) {
  //       centerCursorInView(time, scaleRef.current);
  //       // console.log("center", time);
  //     } else {
  //       debouncedStartCursorInView();
  //       // console.log("start", time);
  //     }
  //     // Remove listener to only run once
  //     timelineState.current?.listener.off("afterSetTime");
  //   });
  // };

  const initScale = () => {
    const initialScale = getInitTimelineScale();
    setScale(initialScale);
    scaleRef.current = initialScale;
  };

  const handleBeforeSetTime = (param: {
    time: number;
    engine: TimelineEngine;
  }) => {
    keepCursorInView(param);
  };

  const getInitTimelineScale = () => {
    if (!cuts.length) return TIMELINE_PROPS.defaultScale;
    const clipDurationWithGaps = cuts[cuts.length - 1].end - cuts[0].start;

    // 1. Calculate the total width required for the timeline at the default scale
    const totalWidthAtDefaultScale =
      (clipDurationWithGaps / TIMELINE_PROPS.defaultScale) *
      TIMELINE_PROPS.scaleWidth;

    // 2. Calculate the available width for the timeline based on windowWidth and any other offsets
    const availableWidth = windowWidth - TIMELINE_PROPS.startLeft * 2;

    // 3. Determine the scale factor needed to fit the timeline within the available width
    const requiredScaleFactor = totalWidthAtDefaultScale / availableWidth;

    // Calculate the new scale based on the required scale factor
    const newScale = TIMELINE_PROPS.defaultScale * requiredScaleFactor;

    // Set the calculated scale
    return Math.round(newScale * 100) / 100;
  };

  const keepCursorInView = (param: {
    time: number;
    engine: TimelineEngine;
  }) => {
    const time = param.time;
    if (time == undefined) return;

    const timelineWidth = timelineState.current?.target.offsetWidth;
    if (!timelineWidth) return;

    const rightBoundary =
      scrollLeftRef.current + timelineWidth - TIMELINE_PROPS.startLeft;
    const leftBoundary = scrollLeftRef.current - TIMELINE_PROPS.startLeft;
    const cursorPosition =
      (time / scaleRef.current) * TIMELINE_PROPS.scaleWidth;

    const isOverflowsRight = cursorPosition > rightBoundary;
    const isOverflowsLeft = cursorPosition < leftBoundary;

    if (isOverflowsLeft || isOverflowsRight) startCursorInView(time);
  };

  const unselectActions = () => {
    console.log("unselect actions");
    setEditorData((prev) => {
      return prev.map((r) => {
        return {
          ...r,
          actions: r.actions.map((a) => {
            return {
              ...a,
              selected: false,
            };
          }),
        };
      });
    });
  };

  const selectAction = (param: {
    action: TimelineAction;
    row: TimelineRow;
  }) => {
    setEditorData((prev) => {
      return prev.map((r) => {
        return {
          ...r,
          actions: r.actions.map((a) => {
            if (a.id === param.action.id) {
              return {
                ...a,
                selected: true,
              };
            }

            return {
              ...a,
              selected: false,
            };
          }),
        };

        return r;
      });
    });
  };

  const updateScale = (newScale: number) => {
    const oldScale = scaleRef.current;
    setScale(newScale);
    scaleRef.current = newScale;

    const timeline = timelineState.current;
    if (!timeline) return;

    const time = timeline.getTime();
    const currentScrollLeft = scrollLeftRef.current;
    if (time === undefined || currentScrollLeft === undefined) return;

    // Calculate cursor's current position on screen
    const cursorScreenPosition =
      (time / oldScale) * TIMELINE_PROPS.scaleWidth - currentScrollLeft;

    // Calculate new scroll position to keep cursor at the same screen position
    const newScrollLeft =
      (time / newScale) * TIMELINE_PROPS.scaleWidth - cursorScreenPosition;
    timeline.setScrollLeft(newScrollLeft);
  };

  const updateScroll = useCallback((param: OnScrollParams) => {
    // console.log("scroll", param.scrollLeft);
    setScrollLeft(param.scrollLeft);
    scrollLeftRef.current = param.scrollLeft;
  }, []);

  const startCursorInView = (time?: number) => {
    const _time = time ?? timelineState.current?.getTime();

    if (_time == undefined) return;
    // console.log("scroll", (_time / scale) * TIMELINE_PROPS.scaleWidth);
    timelineState.current?.setScrollLeft(
      (_time / scaleRef.current) * TIMELINE_PROPS.scaleWidth
    );
  };

  // hack to avoid seeking before components are mounted
  // const debouncedStartCursorInView = useDebouncedCallback(
  //   startCursorInView,
  //   200
  // );

  const centerCursorInView = (time: number, scale: number) => {
    const toScroll = time * (TIMELINE_PROPS.scaleWidth / scale);
    const timelineWidth = timelineState.current?.target.clientWidth;
    if (!timelineWidth) return;
    timelineState.current?.setScrollLeft(toScroll - timelineWidth / 2);
  };

  // const seekToFirstAction = () => {
  //   const firstAction = getEarliestAction(editorData);
  //   if (!firstAction) return;
  //   timelineState.current?.setTime(firstAction.start);
  // };

  const getEarliestAction = (rows: TimelineRow[]): TimelineAction | null => {
    let earliestAction: TimelineAction | null = null;

    for (const row of rows) {
      for (const action of row.actions) {
        if (!earliestAction || action.start < earliestAction.start) {
          earliestAction = action;
        }
      }
    }

    return earliestAction;
  };
  return {
    timelineState,
    editorData,
    selectedActions,
    deleteMode,
    scrollLeft,
    scale,
    selectAction,
    // seekToFirstAction,
    updateScroll,
    updateScale,
    unselectActions,
    startCursorInView,
    centerCursorInView,
    toggleMagnetMode: () => setMagnetMode((prev) => !prev),
  };
};
